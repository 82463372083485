import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../app-constants';
import { CommonService } from 'src/app/common.service';
import { AppStateService } from 'src/app/appStateService';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {
  selectedFile: File;
  errorMessage: boolean = false;
  extensionMessage: boolean = false;
  fileLimitExceed: boolean = false;
  fileLimit: number;
  submitted = false;
  isLoading = false;
  uploadedFileStatuses: { [fileName: string]: any } = {};
  fileUploadStatusFlag: boolean = true;
  fileErrors: { [fileName: string]: string } = {};
  responseData: any;

  constructor(
    private commonService: CommonService,
    public appStateService: AppStateService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.fileLimit = this.appStateService.enviromentData.fileLimit
      ? this.appStateService.enviromentData.fileLimit
      : 5;
  }

  triggerFileUpload(elementId) {
    var element = document.getElementById(elementId);
    element.click();
  }

  onFileChange(target) {
    var files = target.files;
    let fileLimit = this.appStateService.enviromentData.fileLimit;
    let validFileExts = new Set(AppConstants.ValidFileExtensions);
    let maxSize = AppConstants.FileMaxSize;
    this.fileLimitExceed = false;
    this.extensionMessage = false;
    this.errorMessage = false;
    const selectedUploadFiles = [];

    if (files && files.length > 0) {
      let uniqueFileList = this.commonService.selectedFiles.length > 0 ?
        [...files].filter(file => !this.commonService.selectedFiles.find(sf => file.name === sf.fileName)) :
        files;
      if ((uniqueFileList.length + this.commonService.selectedFiles.length) > fileLimit) {
        this.fileLimitExceed = true;
      }
      else {
        for (let file of uniqueFileList) {
          let splitContent = file.name.split('.');
          let fileExt = splitContent[splitContent.length - 1];
          if (!validFileExts.has(fileExt.toUpperCase())) {
            this.extensionMessage = true;
          } else if (file.size > maxSize) {
            this.errorMessage = true;
          } else {
            selectedUploadFiles.push({ file: file, description: (file.description ? file.description : '') });
          }
        }
      }
    }
    this.uploadFiles(selectedUploadFiles);
  }

  removeFile(fileEntry) {
    this.commonService.selectedFiles = this.commonService.selectedFiles.filter(file => file.fileName !== fileEntry.fileName);
    let filetobedeleted = fileEntry.fileName;
    this.commonService.deleteUploadedFiles(
      sessionStorage.getItem("UUID"),
      filetobedeleted,
      res => {
        let success = res.isSuccess ? res.isSuccess : '';
      },
      err => { }
    );

    if (this.responseData && this.responseData.fileUploadResults) {
      this.responseData.fileUploadResults = this.responseData.fileUploadResults.filter(file => file.fileName !== fileEntry.fileName);
    }
  }

  uploadFiles(files): any {
    this.isLoading = true;
    const sessionuuid = sessionStorage.getItem('UUID');
    files.forEach(file => {
      file['uniqueIdentifier'] = sessionuuid.toString();
    });
    this.checkFileStatus();
  
    if(files && files.length > 0){
    this.commonService.verifyUploadedFiles(
      files,
      sessionuuid.toString(),
      res => {
        if (res) {
          this.responseData = res;
          if (res) {            
            this.isLoading = false;
            res.fileUploadResults.forEach(fileEntry => {
              const fileName = fileEntry.fileName;
              if (fileName) {
                this.commonService.selectedFiles.push({ file:null, fileName: fileEntry.fileName, isSuccess:fileEntry.isSuccess, description: '', uniqueIdentifier: sessionuuid.toString(), errorMessage: fileEntry.errorMessage });
              }
            });
          }
        }
      },
      err => {
        this.isLoading = false;
        this.showError(err);
      }
    );
  }else{
    this.isLoading = false;  
  }
  }

  checkFileStatus() {
    const fileErrors = {};
    this.commonService.selectedFiles.forEach(fileEntry => {
      const fileName = fileEntry.fileName;
      if (this.uploadedFileStatuses[fileName] && !this.uploadedFileStatuses[fileName].isSuccess) {
        fileErrors[fileName] = this.uploadedFileStatuses[fileName].malwareStatus;
      }
    });
    this.fileErrors = fileErrors;
  }

  removeFilesWithThreats() {
    this.commonService.selectedFiles = this.commonService.selectedFiles.filter(file => {
      const fileStatus = this.uploadedFileStatuses[file.file.name];
      return fileStatus && fileStatus.malwareStatus === 'NoThreatsFound';
    });
  }

  showError(err: any) {
    this.toastr.error(err.message);
  }

  fileDescriptionChanged(file, event, i) {
    if (this.commonService.selectedFiles) {
      this.commonService.selectedFiles[i].description = file.description;
    }
  }
}