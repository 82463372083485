import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { AppStateService } from "../appStateService";
import { CommonService } from "../common.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-report-summary",
  templateUrl: "./report-summary.component.html",
  styleUrls: ["./report-summary.component.scss"]
})
export class ReportSummaryComponent implements OnInit {
  public passwordForm: FormGroup;
  @Output() backDetails: EventEmitter<any> = new EventEmitter();
  @Output() navigateToTab: EventEmitter<any> = new EventEmitter();
  @Output() ReportNav = new EventEmitter();
  // error_messages = {
  //   password: [
  //     { type: "required", message: "password is required." },
  //     { type: "minlength", message: "password atleast be 6 characters" },
  //     { type: "maxlength", message: "password length." }
  //   ],
  //   confirmpassword: [
  //     { type: "required", message: "password is required." },
  //     { type: "minlength", message: "password atleast be 6 characters" },
  //     { type: "maxlength", message: "password length." }
  //   ]
  // };
  location: string;
  userDetails: any;
  concern: any;
  userAddress: string;
  cid: any;
  submitted = false;
  //isPasswordMismatch: boolean;
  isLoading = false;
  locationName:string

  constructor(
    private fb: FormBuilder,
    public appStateService: AppStateService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitized: DomSanitizer,
    private commonService: CommonService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.route.queryParams.subscribe(params => {
      this.cid = params.cid;
    });
    let locationDetails = this.appStateService.generalDetails.location;
    this.locationName = this.appStateService.generalDetails.location.name ? this.appStateService.generalDetails.location.name : null;
    let res = [];
    res.push(locationDetails.address);
    res.push(locationDetails.city);
    res.push(locationDetails.state);
    res.push(locationDetails.country);
    res.push(locationDetails.postalCode);
    res = res.filter(x => {
      return x;
    });
    this.location = res.join(", ");
    this.userDetails = this.appStateService.generalDetails.userDetails;
    res = [];
    if (this.userDetails) {
      res.push(this.userDetails.address);
      res.push(this.userDetails.city);
      res.push(this.userDetails.state);
      res.push(this.userDetails.postalCode);
      res = res.filter(x => {
        return x;
      });
    }
    this.userAddress = res.join(", ");
    if (!this.appStateService.hideConcern) {
      this.concern = this.sanitized.bypassSecurityTrustHtml(
        this.appStateService.additionalDetails.concern.replace(/\n/g, "<br />")
      );
    }

    this.passwordForm = this.fb.group(
      {
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", [Validators.required, Validators.minLength(6)]]
      },
      {
        validator: (form: FormGroup) => {
          return form.get("password").value !==
            form.get("confirmPassword").value
            ? { PasswordMismatch: true }
            : null;
        }
      }
    );
  }

  // password(formGroup: FormGroup) {
  //   const { value: password } = formGroup.get("password");
  //   const { value: confirmPassword } = formGroup.get("confirmpassword");
  //   return password === confirmPassword ? null : { passwordNotMatch: true };
  // }

  showDisclaimer() {
    this.ReportNav.emit();
  }
  get passwordControl() {
    return this.passwordForm.controls;
  }
  get isPasswordMismatch() { return this.passwordForm.getError('PasswordMismatch') }

  submitReport() {
    this.submitted = true;
    if (this.appStateService.AllowFollowUp) {
      if (
        this.passwordForm.value.password &&
        this.passwordForm.value.confirmPassword &&
        this.passwordForm.value.password ===
        this.passwordForm.value.confirmPassword
      ) {
        // this.isPasswordMismatch = false;
        this.isLoading = true;
        this.saveReport();
      }
    }
    else {
      this.isLoading = true;
      this.saveReport();
    }
    // else {
    //   this.isPasswordMismatch = true;
    // }
  }

  saveReport() {
    const UUID = sessionStorage.getItem('UUID');
    this.appStateService.additionalDetails.documents = "";
    this.commonService.saveReport(
      {
        additionalInformation: this.appStateService.additionalDetails,
        basicInformation: this.appStateService.generalDetails,
        category: this.appStateService.selectedCategory,
        customerId: this.cid,
        uuid:UUID,
        languageCd: this.appStateService.language,
        password: this.passwordForm.value.password,
        hasFiles: this.appStateService.documents && this.appStateService.documents.length > 0
      },
      res => {
        this.appStateService.isSubmitted = true;
        this.appStateService.reportId = res.CRSID;
        this.appStateService.callbackDate = res.CallbackDate;
        this.uploadFiles();        
        this.router.navigate(["/success"]);
        this.commonService.createAudit(
          this.appStateService.customerId,
          "New Report - Success " + this.appStateService.reportId,
          "/success"
        );
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.showError(err);
      }
    );
  }

  showError(err: any) {
    this.toastr.error(err.message);
  }

  onBack() {
    this.backDetails.emit();
  }

  navigate(value) {
    this.navigateToTab.emit(value);
  }

  uploadFiles(): any {
    if (this.appStateService.documents && this.appStateService.documents.length > 0) {
      let filteredDocuments = this.appStateService.documents.filter(doc => doc.isSuccess);
      if(filteredDocuments && filteredDocuments.length > 0){
        this.commonService.uploadDocuments(
          this.appStateService.reportId + "-01",
          this.appStateService.documents,
          res => {
            if (res) {
              this.submitted = true;
              this.isLoading = false;
              this.router.navigate(["/success"]);
              this.commonService.createAudit(
                this.appStateService.customerId,
                "New Report - Success " + this.appStateService.reportId,
                "/success"
              );
            }
          },
          err => {
            this.isLoading = false;
            this.showError(err);
          }
        );
      }
      else {
        this.isLoading = false;
        this.router.navigate(["/success"]);
        this.commonService.createAudit(
          this.appStateService.customerId,
          "New Report - Success " + this.appStateService.reportId,
          "/success"
        );
      }
    } else {
      this.isLoading = false;
      this.router.navigate(["/success"]);
      this.commonService.createAudit(
        this.appStateService.customerId,
        "New Report - Success " + this.appStateService.reportId,
        "/success"
      );
    }
  }
}
